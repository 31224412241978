<template>
  <div class="container" v-loading="loading">

      <div class="row course-part">
        <div class="book-title-header">
          <span class="video-part-title">
            推荐 书籍
          </span>
          <!-- <router-link to="/book/create" class="btn btn-primary create-book-button">创建</router-link> -->
        </div>
        <div class="card-deck" >
          <div class="col-xs-6 col-sm-4 col-md-3" v-for="item in list" :key="item.id">

            <div class="book-image">
              <router-link :to="{ path: item.link, query: { id: item.id } }">
                <img class="card-img-top"
                  :src="item.image"
                  :item="item.title"
                  :alt="item.title" />
              </router-link>
              <div class="book-title">
                <router-link :to="{ path: item.link, query: { id: item.id } }" class="book-title-a">
                  {{ item.title }}
                </router-link>
              </div>
            </div>

            <!-- <div class="card">
              <router-link :to="{ path: item.link, query: { id: item.id } }">
                <img class="card-img-top"
                  :src="item.image"
                  :item="item.title"
                  :alt="item.title" />
              </router-link>
              <div class="card-body">
                <p class="card-text">
                  <small class="text-muted">更新时间：{{ item.update_time }}</small>
                </p>
                <h5 class="card-title">
                  <router-link :to="{ path: item.link, query: { id: item.id } }">
                    {{ item.title }}
                  </router-link>
                </h5>
                <p class="card-text">
                  <small class="text-muted">{{ item.author }} 发布</small>
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  // name: 'Index',
  props:['user'],
  data() {
    return {
      sliders: null,
      list: null,
      loading: true,
    }
  },
  created() {
    this.getNewCourseList()
  },
  methods: {
    async getNewCourseList() {     
      const response = await axios.post('/api/web/book/recommendList')
      const items = response.data.data.data
      this.list = items
      // console.log(this.list)
      this.loading = false
    },
    async course(id) {
      this.$router.push({path:`/course/${id}`})
    }
  }
}
</script>
